<script>
import GalleryForm from "@/views/menu/gallery/galleryForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    galleryService: "galleryService",
  }
})
export default class GalleryEdit extends mixins(GalleryForm) {
  formId = "edit-gallery-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.galleryService.update(this.model.id, {
        idGallery: this.model.id,
        idEvent: this.model.idEvent,
        code: this.model.code,
        localizations: this.model.localizations,
      });
      this.$router.push("/gallery/list");
      toastService.success(this.translations.success.gallery_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.galleries_edit;
  }

  async afterCreate () {
    this.model = await this.galleryService.read(this.$route.params.id);
    this.isReady = true;
  }
}
</script>
